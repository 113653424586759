<template>
  <div class="">
    <transition name="page" mode="out-in">
      <keep-alive>
        <router-view></router-view>
    </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'abonent-info-single-page',
  data: () => ({
    //
  }),
  mounted (){
    //  
  },
  methods: {
    //
  }
}
</script>
